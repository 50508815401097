import React from "react";

const TextInput = ({
    bigLabel,
    label,
    mainDivClass,
    inputStyle,
    placeholder,
    type,
    onChange,
    value,
    disabled,
    name
}) => {
    return (
        <div className={`flex flex-col w-full ${mainDivClass}`}>
            <label
                htmlFor=""
                className={`text-xs font-semibold text-left ${bigLabel}`}
            >
                {label}
            </label>
            <input
                type={type}
                name={name}
                disabled={disabled}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                className={` border border-gray-200 disabled:cursor-not-allowed  rounded-lg text-xs px-2 h-9 outline-none ${inputStyle}`}
            />
        </div>
    );
};

export default TextInput;
