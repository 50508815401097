import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Swal from "sweetalert2";
import { regexEmail } from "../utils/helpers";
import { ERROR } from "../utils/contants";
import PasswordInput from "../components/PasswordInput";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { signUp } from "../services/auth-services";

const DEFAULT_FIELDS = {
    email: "",
    password1: "",
    password2: "",
};
const SignUp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState(DEFAULT_FIELDS);

    function checkValidations() {
        if (!regexEmail(credentials.email)) {
            toast.warn(ERROR.EMAIL_VALIDATION);
            setLoading(false);
            return false;
        } else if (credentials.password1 === "") {
            toast.warn(ERROR.EMPTY_PASSWORD);
            setLoading(false);
            return false;
        } else if (credentials.password1.length < 8) {
            toast.warn(ERROR.PASSWORD_LENGTH);
            setLoading(false);
            return false;
        } else if (credentials.password2 === "") {
            toast.warn(ERROR.CONFORM_EMPTY);
            setLoading(false);
            return false;
        } else if (credentials.password1 !== credentials.password2) {
            toast.warn(ERROR.SAME_ERROR);
            setLoading(false);
            return false;
        }
        return true;
    }
    const handleSignup = () => {
        setLoading(true);
        if (checkValidations()) {
            signUp(credentials)
                .then((res) => {
                    navigate("/signin");
                    Swal.fire({
                        title: "Account created",
                        text: "Please verify your email!",
                        icon: "success",
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response) {
                        const errors = error.response.data;
                        Object.keys(errors).forEach((key) => {
                            if (Array.isArray(errors[key])) {
                                errors[key].forEach((message) => {
                                    toast.error(`${message}`);
                                });
                            } else {
                                toast.error(`${errors[key]}`);
                            }
                        });
                    } else {
                        toast.error("Network error.");
                    }
                });
        }
    };
    const onChangeFields = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };
    return (
        <div className=" w-full min-h-screen px-5 flex flex-col justify-center items-center my-auto ">
            <div className="boxBg border rounded-2xl  border-none  bg-white p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">
                <img src="/Logo.png" alt="" className="w-20 mx-auto" />
                <div className="text-xl font-semibold text-black leading-[35px] text-center">
                    Create Account
                </div>
                <TextInput
                    name={"email"}
                    customClass={"w-full"}
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Enter your email"}
                />
                <PasswordInput
                    name={"password1"}
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Create password"}
                />
                <PasswordInput
                    onChange={(e) => onChangeFields(e)}
                    name="password2"
                    placeholder={"Confirm password"}
                />
                <Button
                    onClick={handleSignup}
                    text={
                        loading ? (
                            <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
                        ) : (
                            "Continue"
                        )
                    }
                    customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
                />
                <div className="text-black text-xs text-center">
                    Have an account already?{" "}
                    <span
                        className="text-primary cursor-pointer"
                        onClick={() => navigate("/signin")}
                    >
                        Sign in
                    </span>
                </div>

            </div>
        </div>
    );
};

export default SignUp;
