import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { regexEmail } from "../utils/helpers";
import { ERROR } from "../utils/contants";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { resetPassword } from "../services/auth-services";
import Swal from "sweetalert2";

const DEFAULT_FIELDS = {
    email: "",
};
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState(DEFAULT_FIELDS);

    function checkValidations() {
        if (!regexEmail(credentials.email)) {
            toast.warn(ERROR.EMAIL_VALIDATION);
            setLoading(false);
            return false;
        }
        return true;
    }
    const handleResetPassword = () => {
        setLoading(true);
        if (checkValidations()) {
            resetPassword(credentials)
                .then((res) => {
                    Swal.fire({
                        text: "Check your email to reset password.",
                        icon: "success",
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    if (err.response) {
                        const errors = err.response.data;
                        Object.keys(errors).forEach((key) => {
                            if (Array.isArray(errors[key])) {
                                errors[key].forEach((message) => {
                                    toast.error(`${message}`);
                                    return
                                });
                            } else {
                                toast.error(`${errors[key]}`);
                            }
                        });
                    } else {
                        toast.error("Network error.");
                    }
                });
        }
    };
    const onChangeFields = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    return (
        <div className="w-full min-h-screen px-5 flex flex-col justify-center items-center my-auto ">
            <div className="border rounded-2xl  border-none boxBg bg-white p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">
                <img src="/Logo.png" alt="" className="w-20 mx-auto" />    <div className="text-xl font-semibold text-black leading-[35px] text-center">
                    Reset Password
                </div>
                <TextInput
                    name="email"
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Enter your email"}
                />

                <Button
                    text={
                        loading ? (
                            <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
                        ) : (
                            "Send Email"
                        )
                    }
                    onClick={handleResetPassword}
                    customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
                />
                <div className="text-black text-xs text-center">
                    Have an account already?{" "}
                    <span
                        className="text-primary cursor-pointer"
                        onClick={() => navigate("/signin")}
                    >
                        Sign in
                    </span>
                </div>
            </div>

        </div>

    );
};

export default ForgotPassword;
