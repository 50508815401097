import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineCardMembership } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";

const size = "h-6 w-6"
const firstDrop = [
  {
    icon: <MdOutlineCardMembership className={size} />,
    text: "Membership Form ",
    link: "/membership-form",
  },
];
const secondDrop = [
  {
    icon: <FaDatabase className={size} />,
    text: "Members Data ",
    link: "/members-data",
  },
];
const SideBar = ({ sidebar,userData }) => {
  const location = useLocation();
  const firstDropdown = true;
  const secondDropdown = true;

  return (
    <aside
      id="logo-sidebar"
      className={`fixed top-0  z-40 ${sidebar
        ? "left-0 sticky top-0 w-[100%] !transition-all duration-1000"
        : "-left-[16.5rem] !transition-all duration-1000 "
        }     h-[100vh] shadow-2xl bg`}
      aria-label="Sidebar"
    >
      <div className="pt-8 pb-4 flex  justify-center">
        <img src="/Logo.png" alt='logo' className="w-32 object-fill" />
      </div>
      <div className="text-center text-white font-semibold">
        <h1 className="text-white">MS FireChiefs</h1>
      </div>
      <div className="px-3 py-4 overflow-y-auto">
        <ul className="space-y-2 font-medium">
          {!userData?.is_staff && firstDropdown &&
            firstDrop.map((d, i) => {
              return (
                <li key={i}>
                  <Link to={d.link}>
                    <div
                      className={`${location.pathname === d.link
                        ? "bg hover:bg-gray-200 hover:!text-black !text-white"
                        : "hover:bg-gray-300 hover:!text-black !text-white"
                        }  flex items-center cursor-pointer py-3 px-2 transition-all duration-300 rounded-lg text-white group`}
                    >
                      <span className="">

                        {d.icon}
                      </span>
                      <span className="ml-3 text-sm whitespace-nowrap">{d.text}</span>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
        <ul className="space-y-2 font-medium">
          {userData?.is_staff && secondDropdown &&
            secondDrop.map((d, i) => {
              return (
                <li key={i}>
                  <Link to={d.link}>
                    <div
                      className={`${location.pathname === d.link
                        ? "bg hover:bg-gray-200 hover:!text-black !text-white"
                        : "hover:bg-gray-300 hover:!text-black !text-black"
                        }  flex items-center cursor-pointer py-3 px-2 transition-all duration-300 rounded-lg group`}
                    >
                      <span className="">
                        {d.icon}
                      </span>
                      <span className="ml-3 text-sm whitespace-nowrap">{d.text}</span>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </aside>
  );
};

export default SideBar;
