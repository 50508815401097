import React, { useEffect, useState } from "react";
import ProfileSidebar from "../components/ProfileSidebar";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Button from '../components/Button'
import PasswordInput from "../components/PasswordInput";
import { toast } from "react-toastify";
import { changePassword, getMemberData, updateMemberData } from "../services/auth-services";
const AccountSettings = () => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [data, setData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    });
    const [userName, setUsername] = useState({
        id: 1628,
        email: "",
        dst: "",
        title: "",
        name: "",
        mi: "",
        address: "",
        city: "",
        st: "",
        zip_code: "",
        notes: "",
        executive_board: false,
        committee_member: false,
        committee: "",
        new_member: false,
        home_phone: "",
        business_phone: "",
        lifetime: false,
        paid_next_year: false,
        lapel_pin: "",
        membership_class: "",
        user: 3,
        department: 3,
        picture: ''
    }) 
    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = () => {
        setLoading(true);
        const tokenData = JSON.parse(localStorage.getItem("token"));
        changePassword(tokenData, data)
            .then((res) => {
                setData(res.data);
                toast.success("Changed successfully!");
                setLoading(false);
            })
            .catch((error) => {
                console.log('error: ', error);
                setLoading(false);
                if (error.response) {
                    const errors = error.response.data;
                    Object.keys(errors).forEach((key) => {
                        if (Array.isArray(errors[key])) {
                            errors[key].forEach((message) => {
                                toast.error(`${message}`);
                            });
                        } else {
                            toast.error(`${errors[key]}`);
                        }
                    });
                } else {
                    toast.error("Network error.");
                }
            });
    };
    const handleChangeForm = (e) => {
        const { name, value } = e.target
        setUsername({ ...userName, [name]: value })

    }
    const getProfileData = () => {
        const tokenData = JSON.parse(localStorage.getItem("token"));
        getMemberData(tokenData)
            .then((res) => {
                setUsername(res.data);
            })
            .catch((error) => {
            });
    };
    useEffect(() => {
        getProfileData();
    }, []);
    const handleUpdate = () => {
        const tokenData = JSON.parse(localStorage.getItem("token"));
        setLoading2(true)
        updateMemberData(userName, tokenData)
            .then((response) => {
                toast.success('Profile updated!')
                setLoading2(false)
            })
            .catch((err) => {
                setLoading2(false)
                console.log(err);
            });
    }
    const handleChangePicture = (e) => {

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUsername({ ...userName, picture: reader.result })
                // setUsername(reader.result);
            };
            reader.readAsDataURL(file);
        }

    }
    return (

        <div className="flex flex-col md:flex-row items-center p-5 gap-10 md:w-3/5 md:mt-20 mx-auto">
            <div className="mx-auto mr-1 ">
                <ProfileSidebar onChangePicture={handleChangePicture} onChange={(e) => handleChangeForm(e)} {...{ userName, loading2, handleUpdate }} />
            </div>
            <div className="basis-full w-full md:basis-1/2 flex flex-col gap-4  mx-auto ">
                <h5 className="font-semibold text-xl text-center text-[#110229]">
                    Change Password
                </h5>
                <PasswordInput
                    label={"Old Password"}
                    name="old_password"
                    value={data?.old_password}
                    topSet={"!top-4"}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Enter password"}
                />
                <PasswordInput
                    value={data?.new_password}
                    label={"New Password"}
                    name="new_password"
                    onChange={(e) => handleChange(e)}
                    topSet={"!top-4"}
                    placeholder={"Enter new password"}
                />
                <PasswordInput
                    label={"New Password"}
                    name="confirm_password"
                    value={data?.confirm_password}
                    topSet={"!top-4"}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Confirm your password"}
                />
                <div className="flex justify-center w-32 mx-auto mt-8">
                    <Button
                        text={
                            loading ? (
                                <AiOutlineLoading3Quarters
                                    size={18}
                                    className="animate-spin"
                                />
                            ) : (
                                "Save"
                            )
                        }
                        onClick={handleSubmit}
                        customClass="!bg-primary rounded-md  text-sm font-semibold text-white !w-32 !h-10"
                    />
                </div>
            </div>
        </div>
    );
};

export default AccountSettings;
