import React, { useEffect, useState } from 'react'
import TableComponent from '../components/DataTable'
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { getPayments, getPaymentsSummary } from '../services/auth-services';
import Button from '../components/Button'
import Loading from '../components/Loading';
const styles = StyleSheet.create({
    mainHeader: {
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 10,
    },
    image: {
        width: 70, // Set the width of the image
        height: 130, // Set the height of the image
        // ... other styles like margin or border
    },
    headerText: {
        color: '#000',
        fontSize: 20,
        fontWeight: '600',
        marginLeft: 100
    },
    headerText3: {
        color: '#000',
        fontSize: 16,
        fontWeight: '600',
        paddingBottom: 10,
        textAlign: "center",
    },
    headerText2: {
        color: '#000',
        fontSize: 20,
        fontWeight: '600',
        textAlign: "center",
    },
    page: {
        flexDirection: 'column',
        width: '210mm',  // A4 width
        minHeight: '297mm', // A4 height
        padding: '10mm', // Adjust padding as needed
    },
    table: {
        display: 'flex',
        flexDirection: 'row',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    row: {
        fontSize: 8,
        flexDirection: 'row',
    },
    header: {
        flexDirection: 'row',
        borderColor: '#000',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    headerCell: {
        textAlign: 'center',
        padding: 3,
        color: '#000',
        fontSize: 8,
    },
    cell: {
        fontSize: 8,
        borderStyle: 'solid',
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        borderColor: '#000',
        padding: 5,

    },
    footer: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 12,
        paddingHorizontal: 20
    },
    headerContainer: {
        backgroundColor: '#bd1e25',
        padding: '10px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 18
    },
    headerTextMain: {
        fontSize: 20,
        color: '#fff',
        fontWeight: '600',
    },
    detailsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rightInvoice: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    invoiceText: {
        fontSize: 8,
    }
});
const Footer = () => (

    <View fixed style={[styles.detailsContainer, styles.footer]}>
        <View style={{ gap: 3, marginTop: 30, marginBottom: 30 }}>
            <Text style={[styles.invoiceText, { fontSize: 10 }]}>DIRECT ALL INQUIRES TO:</Text>
            <Text style={styles.invoiceText}>Richard Watkins</Text>
            <Text style={styles.invoiceText}>(691)981-2915</Text>
            <Text style={styles.invoiceText}>email: rwatkins@msratingbureau.com</Text>
            <Text style={styles.invoiceText}>Subject reference: MFCA Membership</Text>
        </View>
        <View style={styles.rightInvoice}>
            <View style={{ gap: 3 }}>
                <Text style={[styles.invoiceText, { fontSize: 10 }]}>MAKE ALL CHECKS PAYABLE TO:</Text>
                <Text style={styles.invoiceText}>Mississippi Fire Chiefs Association</Text>
                <Text style={styles.invoiceText}>P.O Box 5231</Text>
                <Text style={styles.invoiceText}>Jackson, MS 39296</Text>
            </View>
        </View>

    </View>
);
const PaymentSummary = () => {
    const [payment_data, setPayment_data] = useState([])
    const [successData, setSuccessData] = useState([])
    const [loading, setLoading] = useState(false)
    const TableHeader = () => (
        <View style={[styles.header]}>
            <Text style={[styles.headerCell, { flexBasis: '15%' }]}>QUANTITY</Text>
            <Text style={[styles.headerCell, { flexBasis: '55%' }]}>DESCRIPTION</Text>
            <Text style={[styles.headerCell, { flexBasis: '15%' }]}>UNIT PRICE</Text>
            <Text style={[styles.headerCell, { flexBasis: '15%' }]}>AMOUNT</Text>
        </View>
    )
    const TableRow = ({ name, amount, invoice }) => (
        <View style={styles.row}>
            <Text style={[styles.cell, { flexBasis: '15%', textAlign: "center" }]}>1</Text>
            <Text style={[styles.cell, { flexBasis: '55%', }]}>{'Membership - ' + name + ` (${invoice})`}</Text>
            <Text style={[styles.cell, { flexBasis: '15%', textAlign: 'right', }]}>{amount}</Text>
            <Text style={[styles.cell, { flexBasis: '15%', textAlign: 'right', textAlign: 'right', }]}>{'$' + amount}</Text>
        </View>
    );
    const column = [
        {
            name: "Invoice Number",
            selector: (row) => row.invoice_number,
            width: '200px',
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: '180px',
        },
        {
            name: "Status",
            selector: (row) => row?.status === 'success' ? 'Paid' : 'Unpaid',
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: '180px',
        },
        {
            name: "Total Amount",
            selector: (row) => "$" + row?.total_amount,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: '100px'
        },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <div className='flex items-center'>
                        <PDFDownloadLink document={<MyDocument data={row.payments} />} className='!my-2 !px-2 rounded-full !py-3 !leading-[0px] !text-[10px] bg-primary ml-auto text-white' fileName="PaymentSummary.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'PDF'
                            }
                        </PDFDownloadLink>    <Button disabled={row.status !== 'pending'} onClick={() => handlePayment(row)} customClass={`!my-2 !px-2 !py-3 !leading-[0px] !text-[10px]  ${row.status === 'pending' ? "!bg-primary !text-white" : "!bg-green-500 !text-white"}`} text={row.status === 'pending' ? "Pay Now" : 'Paid'} />
                    </div>

                )
            },
            style: {
                justifyContent: "center",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
        },
    ]
    const columns = [
        {
            name: "Member Name",
            selector: (row) => row?.name,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: '180px',
        },
        {
            name: "Department",
            selector: (row) => row.department,
            width: '200px',
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: '180px',
        },
        {
            name: "Amount",
            selector: (row) => "$" + row?.amount,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
        },
    ]
    const handlePayment = (row) => {
        // console.log('row: ', row);
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("token"));
        const body = { invoice_number: row.invoice_number };
        getPayments(body, token)
            .then((res) => {
                setLoading(false)
                window.location.href = res.data.approval_url;
            })
            .catch((error) => {
                setLoading(false)
                console.log("error: ", error);
            });


    }
    const TotalAmount = ({ data }) => {
        const total = calculateTotal(data);
        return (
            <View style={styles.row}>
                <Text style={[styles.cell, { borderWidth: 1, borderColor: '#000', flexBasis: '70%' }]}></Text>
                <Text style={[styles.cell, { borderWidth: 1, borderColor: '#000', flexBasis: '15%', textAlign: 'center', fontWeight: 600, color: '#000' }]}>SUBTOTAL</Text>
                <Text style={[styles.cell, { borderWidth: 1, borderColor: '#000', flexBasis: '15%', textAlign: 'right', fontWeight: 600, color: '#000' }]}>{total.toFixed(2)}</Text>
            </View>
        );
    };
    const TotalAmount2 = ({ data }) => {
        const total = calculateTotal(data);
        return (
            <View style={styles.row}>
                <Text style={{ flexBasis: '85%' }}></Text>
                <View style={[styles.cell, { borderWidth: 1, gap: 5, borderColor: '#000', flexBasis: '15%', fontWeight: 600, color: '#000' }]}>
                    <Text style={{ textAlign: 'right' }}>${total.toFixed(2)}</Text>
                    <View>

                        <Text style={{ textAlign: 'center', fontSize: 8, color: 'gray' }}>PAY THIS</Text>
                    </View>
                    <View>

                        <Text style={{ textAlign: 'center', fontSize: 8, color: 'gray' }}>AMOUNT</Text>
                    </View>
                </View>
            </View>
        );
    };
    const MyDocument = ({ data }) => {

        return (
            <Document>
                <Page size={'A4'} style={styles.page}>
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerTextMain}>Mississippi Fire Chiefs Association</Text>
                        <Text style={styles.headerTextMain}>INVOICE</Text>
                    </View>
                    <View style={{ width: 200, paddingTop: 10, textAlign: 'center', marginLeft: '50', fontSize: 10 }}>
                        <Text>P.O. Box 5231</Text>
                        <Text>Jackson , MS 39296-5231</Text>
                    </View>
                    <View style={styles.detailsContainer}>
                        <View style={{ gap: 3, marginTop: 30, marginBottom: 30 }}>
                            <Text style={[styles.invoiceText]}>BILLED TO:</Text>
                            <Text style={styles.invoiceText}>Moss Point Fire Department </Text>
                            <Text style={styles.invoiceText}>402 Bellview Ave</Text>
                            <Text style={styles.invoiceText}>Moss Point, MS 83759</Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: 12 }}>
                                INVOICE STATUS: <Text style={{ color: data[0].status === 'pending' ? 'red' : 'green' }}>{data[0].status === 'pending' ? 'UNPAID' : "PAID"}</Text>
                            </Text>
                        </View>
                        <View style={styles.rightInvoice}>
                            <View style={{ borderRightWidth: 1, paddingRight: 5, marginRight: 5, gap: 3 }}>
                                {/* <Text style={[styles.invoiceText, { marginLeft: 'auto', fontSize: 8 }]}>INVOICE DATE</Text> */}
                                <Text style={[styles.invoiceText, { marginLeft: 'auto', fontSize: 8 }]}>DUE Date</Text>
                            </View>
                            <View style={{ gap: 3 }}>
                                <Text style={[styles.invoiceText, { fontSize: 8 }]}>January 1, {new Date().getFullYear()}</Text>
                            </View>
                        </View>
                    </View>
                    {/* </View> */}
                    <TableHeader />
                    {data.map(item => (
                        <TableRow key={Math.random() + item.name} invoice={item.invoice_number} date={item.date} name={item.name} amount={item.amount} />
                    ))}
                    <TotalAmount data={data} />
                    <TotalAmount2 data={data} />
                    <Footer />
                </Page>
            </Document>
        )
    }
    const calculateTotal = (data) => {
        return data.reduce((acc, curr) => acc + parseFloat(curr?.amount?.replace('$', '')), 0);
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        getPaymentsSummary(token).then((res) => {
            setPayment_data(res.data)
            // const successFilter = res.data.filter(item => item.status === 'success');
            // setSuccessData(successFilter)
        }).catch((err) => {
            console.log('err: ', err);
        })
    }, [])
    return (
        <>
            {loading && <Loading />}
            <div className='mx-auto w-fit text-center flex flex-col justify-center items-center '>
                <div className='text-xl font-semibold py-10'>Payment Summary</div>
                <div className='w-full mx-auto'>
                    {/* <PDFDownloadLink document={<MyDocument data={successData} />} className='text-xs p-2 mb-3 px-4 rounded-md flex w-fit justify-end bg-primary ml-auto text-white' fileName="PaymentSummary.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Export PDF'
                        }
                    </PDFDownloadLink> */}
                    <TableComponent expandableRows expandableRowsComponent={row => {
                        return (
                            <TableComponent pagination={false} expandableRowDisabled={row => row?.id} expandableRows column={columns} data={row?.data?.payments} />
                        )
                    }} data={payment_data} column={column} />
                </div>
            </div>
        </>
    )
}
export default PaymentSummary