import React, { useState } from "react";
import { useEffect } from "react";
import TableComponent from "../components/DataTable";
import Select from "react-select";
import {
  getPayments,
  getPublicMember,
  getPublicMembersData,
} from "../services/auth-services";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { TiTick } from "react-icons/ti";
import { AiOutlineClear } from "react-icons/ai";
import { toast } from "react-toastify";
import { Slide } from "react-reveal";
import Loading from "../components/Loading";

const PublicMembersData = () => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null)
  const [filters, setFilters] = useState([
    { column: "", type: "icontains", query: "", start_date: "", end_date: "" },
  ]);
  const [columns, setColumns] = useState([
    { value: "last_name", label: "Last name" },
    { value: "address", label: "Address" },
  ]);
  const filterTypes = [
    { value: "startswith", label: "Starts with" },
    { value: "icontains", label: "Includes" },
  ];
  const handleFilterChange = (index, field, value, type) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    newFilters[index].filterType = type;
    setFilters(newFilters);
  };

  const addFilter = () => {
    setFilters([
      ...filters,
      {
        column: "",
        type: "icontains",
        query: "",
        start_date: "",
        end_date: "",
      },
    ]);
  };
  const removeFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const applyFilters = () => {
    fetchEmployeesWithFilters(filters);
  };
  const handleClear = (index) => {
    setFilters((currentFilters) =>
      currentFilters.map((filter, filterIndex) =>
        filterIndex === index
          ? {
            column: "",
            type: "startswith",
            query: "",
            start_date: "",
            end_date: "",
          }
          : filter
      )
    );
  };
  const fetchEmployeesWithFilters = async (filters) => {
    setLoading(true)
    const hasEmptyValues = filters.some((filter) =>
      filter.column === "" || filter.column === "date_paid"
        ? filter.start_date === "" || filter.end_date === ""
        : filter.query === ""
    );
    if (hasEmptyValues) {
      toast.warn("Please fill all fields for filters.");
      setLoading(false)
    } else {
      const tokenData = JSON.parse(localStorage.getItem("token"));

      getPublicMembersData(filters, tokenData)
        .then((res) => {
          setData(res?.data?.results);
          setLoading(false);
          if (res.data.length > 0) {
            setColumns(Object.keys(res.data.results[0]));
          }
        })
        .catch((error) => {
          console.error("Error fetching filtered employees:", error);
          setLoading(false);
        });
    }
  };
  const handleMembersData = () => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const tokenData = JSON.parse(localStorage.getItem("token"));

    getPublicMember(tokenData)
      .then((res) => {
        setData(res?.data.results);
        setColumns(
          Object.keys(res?.data.results[0]).map((key) => ({
            value: key,
            label: key
              .replace(/_/g, " ")
              .replace(/^\w/, (c) => c.toUpperCase()),
            type: typeof res.data.results[0][key]
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleSelectRow = (e) => {
    setSelectedRows(e)
  }
  useEffect(() => {
    handleMembersData();
  }, []);

  const column = [
    {
      name: "Id",
      selector: (row) => row.id,
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "60px"
    },
    {
      name: "Name",
      selector: (row) => row?.first_name + " " + row.last_name,
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "200px"
    },

    {
      name: "Department",
      selector: (row) => (row?.department ? row?.department : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "250px"
    },

    {
      name: "City",
      selector: (row) => (row?.city ? row?.city : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "250px"
    },
  ];
  const handlePayment = () => {
    setLoading(true)
    const token = JSON.parse(localStorage.getItem("token"));
    const body = { selectedMembers: selectedRows?.selectedRows }
    getPayments(body, token).then((res) => {
      window.location.href = res.data.approval_url
    }).catch((error) => {
      console.log('error: ', error);
    })
  }
  // const generateCSVData = () => {
  //   const csvData = data.map((item) => ({
  //     ID: item.id,
  //     First_name: item?.first_name,
  //     Last_name: item?.last_name,
  //     Department: item?.department,
  //     City: item?.city
  //   }));
  //   return csvData;
  // };
  return (
    <div className="px-5 mt-5">
      {loading ?
        <Loading />
        :
        <>
          <div className="text-3xl my-6 md:my-6 text-center mx-auto w-fit text-primary font-medium">
            Members Data
          </div>
          <div className="md:w-1/2 mb-5 flex flex-col gap-5 mx-auto border p-5 rounded-lg shadow-xl">
            {filters.map((filter, index) => (
              <div className="flex flex-col gap-5 border px-5 py-3 rounded-lg">
                <div key={index} className="flex gap-5 w-full">
                  <div className="w-full">
                    <Select
                      options={columns}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          fontSize: "12px",
                          ":focus": {
                            borderColor: "black",
                          },
                          backgroundColor: state.isFocused
                            ? "transparent"
                            : "transparent",
                          indicatorsContainer: (prevStyle, state) =>
                            state.isMulti
                              ? {
                                ...prevStyle,
                              }
                              : null,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#000000",
                          fontSize: "12px",
                        }),
                      }}
                      value={
                        columns.find(
                          (option) => option.value === filters[index].column
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleFilterChange(index, "column", selectedOption.value, selectedOption.type)
                      }
                      placeholder="Select Column"
                    />
                  </div>
                  {filter.column !== "date_paid" && filter.filterType !== "boolean" ? (
                    <div className="w-full">
                      <Select
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            fontSize: "12px",
                            ":focus": {
                              borderColor: "black",
                            },
                            backgroundColor: state.isFocused
                              ? "transparent"
                              : "transparent",
                            indicatorsContainer: (prevStyle, state) =>
                              state.isMulti
                                ? {
                                  ...prevStyle,
                                }
                                : null,
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "#000000",
                            fontSize: "12px",
                          }),
                        }}
                        options={filterTypes}
                        value={filterTypes.find(
                          (option) => option.value === filters[index].type
                        )}
                        onChange={(selectedOption) =>
                          handleFilterChange(index, "type", selectedOption.value)
                        }
                        placeholder="Filter Type"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="flex gap-5 items-center ">
                  {filter?.column === "date_paid" ? (
                    <div className="flex gap-5 w-full">
                      <TextInput
                        label={"Start date"}
                        type={"date"}
                        onChange={(e) =>
                          handleFilterChange(index, "start_date", e.target.value)
                        }
                      />
                      <TextInput
                        label={"End date"}
                        type={"date"}
                        onChange={(e) =>
                          handleFilterChange(index, "end_date", e.target.value)
                        }
                      />
                    </div>
                  ) : filter.filterType === "boolean" ?
                    <select className="border border-gray-300 rounded-md text-xs px-2 h-[38px] outline-blue-400 w-full" id="cars" onChange={(e) => handleFilterChange(index, "query", e.target.value, 'boolean')} >
                      <option value="">select true or false</option>
                      <option value="1">True</option>
                      <option value="0">False</option>
                    </select> : (
                      <TextInput
                        type="text"
                        placeholder={`Enter your query..`}
                        value={filter.query}
                        onChange={(e) =>
                          handleFilterChange(index, "query", e.target.value)
                        }
                      />
                    )}
                  <div className="flex items-center">
                    <div
                      onClick={() => handleClear(index)}
                      className="rounded-full cursor-pointer h-[35px] border shadow-sm w-[35px] p-1 flex flex-col items-center justify-center"
                    >
                      <AiOutlineClear className=" h-4 w-4 " />
                    </div>
                    {filters.length > 1 && (
                      <svg
                        onClick={() => removeFilter(index)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <g filter="url(#filter0_d_44_1073)">
                          <rect
                            x="6"
                            y="6"
                            width="28"
                            height="28"
                            rx="14"
                            fill="white"
                          />
                          <path
                            d="M15.0992 25.6C15.0992 25.9713 15.2467 26.3274 15.5093 26.5899C15.7718 26.8525 16.1279 27 16.4992 27H23.4992C23.8705 27 24.2266 26.8525 24.4892 26.5899C24.7517 26.3274 24.8992 25.9713 24.8992 25.6V17.2H26.2992V15.8H23.4992V14.4C23.4992 14.0287 23.3517 13.6726 23.0892 13.4101C22.8266 13.1475 22.4705 13 22.0992 13H17.8992C17.5279 13 17.1718 13.1475 16.9093 13.4101C16.6467 13.6726 16.4992 14.0287 16.4992 14.4V15.8H13.6992V17.2H15.0992V25.6ZM17.8992 14.4H22.0992V15.8H17.8992V14.4ZM17.1992 17.2H23.4992V25.6H16.4992V17.2H17.1992Z"
                            fill="#CF5D5E"
                          />
                          <path
                            d="M17.9004 18.6H19.3004V24.2H17.9004V18.6ZM20.7004 18.6H22.1004V24.2H20.7004V18.6Z"
                            fill="#CF5D5E"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_44_1073"
                            x="0"
                            y="0"
                            width="40"
                            height="40"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="3" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_44_1073"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_44_1073"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex gap-5 justify-end">
              <Button
                customClass={"!text-xs "}
                onClick={addFilter}
                text={"+ Add Filter"}
              />
              <Button customClass={"!text-xs !gap-0"} onClick={applyFilters}>
                <TiTick /> Apply Filter
              </Button>
            </div>
          </div>
          <div className="">
            <div className="w-fit mx-auto pb-10">

              {
                data && <TableComponent selectableRows onSelectedRowsChange={handleSelectRow} progressPending={loading} {...{ data, column }} />
                //
              }
            </div>
            {selectedRows?.selectedCount > 0 &&
              <Slide right>

                <div className="fixed right-3 bottom-3 bg-white  px-5 py-2  ">
                  <Button customClass={"!text-xs hover:scale-90 transition-all group hover:bg-primary hover:text-white duration-1000 !gap-2"} onClick={handlePayment}>
                    <span className="rounded-full cursor-pointer border border-primary group-hover:!border-white p-1 px-2">

                      {selectedRows.selectedCount}
                    </span>
                    Pay ${selectedRows?.selectedCount * 45} Now
                  </Button>
                </div>
              </Slide>
            }
          </div>
        </>
      }
    </div>
  );
};

export default PublicMembersData;
