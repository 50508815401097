import React from "react";

const Success = () => {
    return (
        <>
            <img src="/thanku.png" alt="" className="mx-auto mt-[15%]" />
            <div className="text-center text-3xl text-primary mt-5 font-medium">
                Thank you for purchasing membership!
            </div>
        </>
    );
};

export default Success;
