import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ERROR } from "../utils/contants";
import PasswordInput from "../components/PasswordInput";
import Button from "../components/Button";
import { confirmPassword } from "../services/auth-services";

const DEFAULT_FIELDS = {
    reset_key: "",
    new_password: "",
};
const ConfirmPassword = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
    const checkValidations = () => {
        if (credentials.new_password === "") {
            toast.warn(ERROR.EMPTY_PASSWORD);
            setLoading(false);
            return false;
        } else if (credentials.new_password.length < 8) {
            toast.warn(ERROR.PASSWORD_LENGTH);
            setLoading(false);
            return false;
        } else if (credentials.password2 === "") {
            toast.warn(ERROR.CONFORM_EMPTY);
            setLoading(false);
            return false;
        } else if (credentials.new_password !== credentials.password2) {
            toast.warn(ERROR.SAME_ERROR);
            setLoading(false);
            return false;
        }
        return true;
    };
    useEffect(() => {
        setCredentials({
            ...credentials,
            reset_key: location.search.replace("?key=", ""),
        });
        // eslint-disable-next-line
    }, [location]);
    const handleConfirmPassword = () => {
        setLoading(true);
        if (checkValidations()) {
            confirmPassword(credentials)
                .then((res) => {
                    window.location.href = "/signin";
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response) {
                        const errors = error.response.data;
                        Object.keys(errors).forEach((key) => {
                            if (Array.isArray(errors[key])) {
                                errors[key].forEach((message) => {
                                    toast.error(`${message}`);
                                });
                            } else {
                                toast.error(`${errors[key]}`);
                            }
                        });
                    } else {
                        toast.error("Network error.");
                    }
                });
        }
    };
    const onChangeFields = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    return (
        <div className="w-full min-h-screen px-5 flex flex-col justify-center items-center my-auto ">
            <div className="border rounded-2xl  border-none boxBg bg-white p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">
                <img src="/Logo.png" alt="" className="w-20 mx-auto" />
                <div className="text-xl font-semibold text-black leading-[35px] text-center">
                    Confirm Password
                </div>
                <PasswordInput
                    name={"new_password"}
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"New password"}
                />
                <PasswordInput
                    onChange={(e) => onChangeFields(e)}
                    name="password2"
                    placeholder={"Confirm password"}
                />
                <Button
                    text={
                        loading ? (
                            <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
                        ) : (
                            "Change Password"
                        )
                    }
                    onClick={handleConfirmPassword}
                    customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
                />
                <div className="text-black text-xs  text-center ">
                    New here?{" "}
                    <span
                        onClick={() => navigate("/signup")}
                        className="text-primary cursor-pointer"
                    >
                        Create an account
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPassword;
