import React from "react";
import Button from '../Button'
const FromFooter = ({
    handleBack,
    isProgress,
    totalSteps,
    handleNext,
    handleSubmit,
    handlePayment,
    agreeTerms,
}) => {
    return (

        <div className="flex gap-3 justify-end mt-10">
            {isProgress !== 1 && (
                <Button
                    disabled={isProgress === 1}
                    onClick={handleBack}
                    text={"Previous"}
                    customClass={
                        " !bg-transparent border !text-primary !text-sm md:text-base !border-[#D0D5DD] !py-1.5 disabled:cursor-not-allowed"
                    }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="10"
                        className="group-hover:stroke-primary"
                        viewBox="0 0 16 10"
                    >
                        <path
                            d="M1.33316 4.99976L14.6665 4.99976"
                            stroke="#455571"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M5.49967 9.16659C5.49967 9.16659 1.33305 6.09788 1.33304 4.99989C1.33303 3.90189 5.49971 0.833252 5.49971 0.833252"
                            stroke="#455571"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </Button>
            )}
            <Button
                onClick={isProgress === totalSteps ? handleSubmit : handleNext}
                text={isProgress === totalSteps ? "Pay Now" : "Next"}
                disabled={isProgress === 3 && !agreeTerms}
                customClass={
                    "group !text-sm md:text-base flex-row-reverse text-white !bg-primary !py-1.5 disabled:cursor-not-allowed"
                }
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="!stroke-white"
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                >
                    <path
                        d="M14.1668 4.99976L0.833435 4.99976"
                        // stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.0002 9.16659C10.0002 9.16659 14.1668 6.09788 14.1669 4.99989C14.1669 3.90189 10.0002 0.833252 10.0002 0.833252"
                        // stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Button>
        </div>

    );
};

export default FromFooter;
