import React from "react";
import DataTable from "react-data-table-component";

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#bd1e25',
            color: "#fff",
            justifyContent: "left",
            fontWeight: "700",
            fontSize: "12px",
        },
    },
    bodyCells: {
        style: {
            color: "black",
        },
    },

};
const pageOption = {
    noRowsPerPage: false,
};
const TableComponent = ({
    id,
    data,
    column,
    actions,
    onChangePage,
    expandableRows,
    selectableRows,
    progressPending,
    subHeaderComponent,
    onChangeRowsPerPage,
    paginationTotalRows,
    onSelectedRowsChange,
    expandableRowDisabled,
    expandableRowsComponent,
    pagination = true
}) => {
    return (
        <DataTable
            id={id}
            pagination={pagination}
            fixedHeader
            responsive
            dense
            striped
            data={data}
            expandableRows={expandableRows}
            expandableRowDisabled={expandableRowDisabled}
            expandableRowsComponent={expandableRowsComponent}
            actions={actions}
            columns={column}
            selectableRows={selectableRows}
            customStyles={customStyles}
            onChangePage={onChangePage}
            paginationPerPage={15}
            customTableClass="my-table"
            fixedHeaderScrollHeight="85vh"
            progressPending={progressPending}
            paginationRowsPerPageOptions={[50, 40, 30, 15]}
            highlightOnHover
            onSelectedRowsChange={onSelectedRowsChange}
            subHeaderComponent={subHeaderComponent}
            paginationComponentOptions={pageOption}
            paginationTotalRows={paginationTotalRows}
            onChangeRowsPerPage={onChangeRowsPerPage}
        />
    );
};

export default TableComponent;