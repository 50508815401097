import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import MembershipDashboard from "./pages/MemberShipDashboard";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import ConfirmPassword from "./pages/confirm-password";
import MembersData from "./pages/Dashboard/members";
// import { isAuthenticated } from "./utils/helpers";
// import { jwtDecode } from "jwt-decode";
import PublicMembersData from "./pages/public-members";
import Cancelled from "./pages/cancelled";
import Success from "./pages/success";
import NotFound from "./pages/404";
import PaymentSummary from "./pages/payment-summary";
import AccountSettings from "./pages/account-settings";

const App = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user?.exp) {
      return false;
    }
    try {
      const expirationTime = user.exp * 1000;
      const currentTime = Date.now();
      if (currentTime >= expirationTime) {
        localStorage.clear();
        window.location.href = "/signin";
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <Routes>
      {/* auth routes */}
      <Route
        path={"/"}
        element={isAuthenticated() ? <Navigate to={user?.is_staff ? '/members-data' : '/membership-form'} /> : <Navigate to={'/signin'} />}
      />
      <Route
        path={"/signin"}
        element={isAuthenticated() ? <Navigate to={user?.is_staff ? '/members-data' : '/membership-form'} /> : <SignIn />}
      />
      <Route
        path={"/reset-password"}
        element={isAuthenticated() ? <Navigate to={user?.is_staff ? '/members-data' : '/membership-form'} /> : <ForgotPassword />}
      />
      <Route path={"/signup"} element={<SignUp />} />
      <Route path={"/forgot-password"} element={<ForgotPassword />} />
      <Route path={"/confirm-password"} element={<ConfirmPassword />} />

      {/* payment */}
      {/* payment-summary */}
      <Route
        path={"/payment_cancelled"}
        element={isAuthenticated() ? <Cancelled /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/account-setting"}
        element={isAuthenticated() ? <AccountSettings /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/payment-summary"}
        element={isAuthenticated() ? <PaymentSummary /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/payment_success"}
        element={isAuthenticated() ? <Success /> : <Navigate to="/signin" />}
      />
      {/* member pages  */}
      <Route path={"/public-members"} element={<PublicMembersData />} />
      <Route path={"/membership-form"} element={<MembershipDashboard />} />
      {/* admin routes */}
      <Route path={"/members-data"} element={<MembersData />} />
      {/* if path not found */}
      <Route path="*" element={isAuthenticated() ? <NotFound /> : <Navigate to={"signin"} />} />
    </Routes>
  );
};
export default App;