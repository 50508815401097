import { HiOutlineLogin } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const ProfileSidebar = ({ userName, onChange, onChangePicture, loading2, handleUpdate, }) => {
  const navigation = useNavigate();
  return (
    <div className="main-div-sidebar  w-full ">
      <div className="relative flex justify-center py-5">
        <div className=" flex items-center justify-center relative overflow-  ">
          {userName?.picture !== '' ? (
            <>
            {userName.picture ?
            <img
            src={userName.picture}
            alt="Image"
            className="object-cover h-32 w-32 rounded-full"
            />
          :
            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="object-cover !h-32 !w-32 rounded-full"
            viewBox="0 0 120 120"
            fill="none"

              >
                <g clipPath="url(#a)">
                  <rect width={120} height={120} rx={60} fill="#D7D7D7" />
                  <path
                    d="M41.34 78.27c-12.16 5.615-21.352 16.557-24.594 29.83-1.62 6.629 1.786 13.455 8.09 16.071C31.78 127.054 43.03 130 60 130s28.22-2.946 35.165-5.83c6.303-2.616 9.71-9.442 8.09-16.071-3.243-13.273-12.436-24.215-24.594-29.828z"
                    fill="#fff"
                  />
                  <path
                    d="M60 84.81c16.516 0 29.905-13.389 29.905-29.905S76.515 25 60 25 30.095 38.389 30.095 54.905 43.484 84.81 60 84.81"
                    fill="#fff"
                    />
                  <path
                    d="M38.924 54.695a2.266 2.266 0 0 1-2.266-2.266c0-12.246 8.673-18.373 13.258-19.901a2.266 2.266 0 0 1 1.441 4.297c-.441.152-10.166 3.648-10.166 15.604a2.266 2.266 0 0 1-2.267 2.266m.001 7.177a1.888 1.888 0 1 0 0-3.777 1.888 1.888 0 0 0 0 3.777"
                    fill="#D7D7D7"
                    />
                </g>
                <defs>
                  <clipPath id="a">
                    <rect width={120} height={120} rx={60} fill="#fff" />
                  </clipPath>
                </defs>
              </svg>
                  }
              {/* <img
                src="/create.png"
                alt=""
              /> */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={44}
                className="absolute bottom-0 right-[-5px]"
                height={44}
                viewBox="0 0 44 44"
                fill="none"
              >
                <rect x={2} y={2} width={40} height={40} rx={20} fill="#bd1e25" />
                <rect
                  x={2}
                  y={2}
                  width={40}
                  height={40}
                  rx={20}
                  stroke="#fff"
                  strokeWidth={3}
                />
                <path
                  d="M22 16v12m-6-6h12"
                  stroke="#fff"
                  strokeWidth={2.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          ) : (
            <img
              className="h-[120px] w-[120px]"
              src="/dummy_pro.png"
              alt=""
            />
          )}
          <input
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer  "
            type={"file"}
            accept="image/png, image/jpg, image/jpeg"
            name="profileImage"
            onChange={onChangePicture}
          />
        </div>
      </div>
      {userName.email &&
        <div className="text-primary text-xs border-primary shadow-md border p-2 rounded-md w-fit mx-auto mb-3">Click on the "+" button to upload your profile picture.</div>
      }
      <div className="mb-10 text-center">
        <p className="leading-5 font-semibold text-[#272B41] text-base">
          {userName?.name
            ? <input type="text" value={userName.name} name="name" className="w-fit text border px-1 text-xs text-center  py-1 rounded-md truncate" onChange={onChange} />
            : "Your Name"}
        </p>
      </div>
      {userName.email &&
        <div className="text-primary text-xs border-primary shadow-md border p-2 rounded-md">Info : Click on the bordered boxes to edit your info and then press the update button</div>
      }
      {userName?.email &&
        <div className="flex gap-5 items-center justify-between py-2">
          <div className="font-semibold text-sm">Email:</div>
          <input type="text" value={userName.email} name="email" disabled className="w-fit truncate text-xs" onChange={onChange} />
        </div>
      }
      {userName?.home_phone &&
        <div className="flex gap-5 items-center justify-between py-2">
          <div className="font-semibold text-sm">Phone Number:</div>
          <input type="text" value={userName.home_phone} name="home_phone" className="w-fit truncate border px-1 text-xs py-0.5 rounded-md" onChange={onChange} />
        </div>
      }

      {userName?.title &&
        <div className="flex gap-5  items-center justify-between py-2">
          <div className="font-semibold w-[250px] text-sm">Title:</div>
          <input type="text" value={userName.title} name="title" className="w-fit truncate border px-1 text-xs py-0.5 rounded-md" onChange={onChange} />
        </div>
      }

      {userName?.address &&
        <div className="flex gap-5  items-center justify-between py-2">
          <div className="font-semibold w-[250px] text-sm">Address:</div>
          <input type="text" value={userName.address} name="address" className="w-fit truncate border px-1 text-xs py-0.5 rounded-md" onChange={onChange} />
        </div>
      }

      {userName?.city &&
        <div className="flex gap-5  items-center justify-between py-2">
          <div className="font-semibold w-[250px] text-sm">City:</div>
          <input type="text" value={userName.city} name="city" className="w-fit truncate border px-1 text-xs py-0.5 rounded-md" onChange={onChange} />
        </div>
      }

      {userName?.st &&
        <div className="flex gap-5  items-center justify-between py-2">
          <div className="font-semibold w-[250px] text-sm">ST:</div>
          <input type="text" value={userName.st} name="st" className="w-fit truncate border px-1 text-xs py-0.5 rounded-md" onChange={onChange} />
        </div>
      }
      {userName?.zip_code &&
        <div className="flex gap-5  items-center justify-between py-2">
          <div className="font-semibold w-[250px] text-sm">Zip code:</div>
          <input type="text" value={userName.zip_code} name="zip_code" className="w-fit truncate border px-1 text-xs py-0.5 rounded-md" onChange={onChange} />
        </div>
      }
      {userName.email &&
        <div className="flex justify-center w-32 mx-auto mt-8">
          <Button
            text={
              loading2 ? (
                <AiOutlineLoading3Quarters
                  size={18}
                  className="animate-spin"
                />
              ) : (
                "Update"
              )
            }
            onClick={handleUpdate}
            disabled={loading2}
            customClass="!bg-primary rounded-md  text-sm font-semibold text-white !w-32 !h-10"
          />
        </div>
      }
      <hr className="my-4" />
      <div
        onClick={() => {
          localStorage.removeItem("user");
          navigation('/signin')
        }}
        className="flex items-center gap-2 mx-auto w-full text-sm py-2 cursor-pointer"
      >
        <div className="side-bar-icon-sel">
          <HiOutlineLogin size={20} />
        </div>
        <p className="icon-para-sel !block">Logout</p>
      </div>
    </div>
  );
};

export default ProfileSidebar;