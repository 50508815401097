import { Link } from "react-router-dom";

const Button = ({
    bg,
    color,
    textDir,
    fontSize,
    fontWeight,
    onClick,
    text,
    padding,
    type,
    disabledClass,
    disabled,
    customClass,
    children,
    id, to
}) => {
    return (
        <Link to={to}>
            <button
                type={type}
                onClick={onClick}
                id={id}
                disabled={disabled}
                className={`h-full blocks flex items-center justify-center border  gap-3 text-[#000] text-base bg-white font-medium leading-[24px] !w-fit px-6 py-2 rounded-xl  ${padding} ${bg} ${color} ${textDir} ${fontSize} ${fontWeight} ${disabledClass} ${customClass}`}
            >
                {children}{text}
            </button>
        </Link>
    );
};

export default Button;