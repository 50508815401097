import React from "react";
import { CgSpinnerTwo } from "react-icons/cg";


const Loading = ({ text }) => {
    return (
        <div
            className="bg-center"
            style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px",
                display: "block",
                zIndex: 100,
                backgroundColor: "rgb(0,0,0,0.9)",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div>
                    <CgSpinnerTwo size={50} className="text-white mx-auto animate-spin" />
                    <div id="loadingText" className="text-white text-sm text-center mt-5">
                        {text ? text : "Please wait"} . . .{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
